
export default {
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      await this.$store.dispatch('liveEdit/login', this.form);
      this.isLoading = false;
    },
  },
};
