
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    hash: {
      type: String,
      required: true,
    },
  },

  computed: {
    isEditMode() {
      return this.$liveEdit.isEditMode();
    },
  },

  methods: {
    remove() {
      if (confirm('Are you sure?') && this.$vnode.key && this.$vnode.key.length) {
        this.$liveEdit.removeFromList(this.hash, this.$vnode.key);
      }
    },
  },
};
