import { render, staticRenderFns } from "./Textarea.vue?vue&type=template&id=057eaf70&scoped=true&"
import script from "./Textarea.vue?vue&type=script&lang=js&"
export * from "./Textarea.vue?vue&type=script&lang=js&"
import style0 from "./Textarea.vue?vue&type=style&index=0&id=057eaf70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057eaf70",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Textarea: require('/vercel/path0/frontend/components/ui/Textarea.vue').default})
