
export default {
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
    hash: {
      type: String,
      default: null,
    },
  },
  computed: {
    isEditMode() {
      return this.$liveEdit.isEditMode();
    },
    isExternal() {
      if (typeof this.to === 'object') {
        return false;
      }

      let link = this.to;

      if (this.hash) {
        link = this.$liveEdit.getText(this.hash);
      }

      return !/^\/(?!\/)/.test(link);
    },
    link() {
      let link = this.to;

      if (this.hash) {
        link = this.$liveEdit.getText(this.hash);
      }

      if (!this.isExternal) {
        link = this.localePath ? this.localePath(link) : link;
      }

      return link;
    },
    tag() {
      return this.isExternal || this.isEditMode ? 'a' : 'nuxt-link';
    },
  },

  methods: {
    handleClick(event) {
      if (this.isEditMode && !event.target.classList.contains('FileInput')) {
        event.preventDefault();
      }
    },
    openLink() {
      if (this.isEditMode) {
        if (this.isExternal) {
          window.open(this.link, this.target);
        } else {
          this.$router.push(this.link);
        }
      }
    },
  },
};
