
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onUpdate({ target }) {
      this.$emit('input', target.value);
    },

    async focus() {
      await this.$nextTick();
      this.$el.focus();
    },
  },
};
