
export default {
  props: {
    hash: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: 'jpg',
    },
    loading: {
      type: [String, Boolean],
      default: 'lazy',
    },
    transform: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    imgClass: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isImagekitEnabled: true,
      isEmpty: false,
      isLoading: false,
    };
  },

  computed: {
    isEditMode() {
      return this.$liveEdit.isEditMode();
    },

    version() {
      return this.$store.getters['liveEdit/getText']('live-image-version', this.$liveEdit.getLocale(this.$i18n));
    },

    normalizedHash() {
      return this.hash.toLowerCase().replace(/\./g, '-');
    },

    imagekitUrl() {
      return this.$store.state.liveEdit.imagekitUrl;
    },

    src() {
      let url = this.$store.state.liveEdit.assetsUrl;

      if (this.isImagekitEnabled) {
        if (this.transform) {
          url = `${this.transform}/${url}`;
        }

        url = `${this.imagekitUrl}/${url}`;
      }

      return `${url}/${this.normalizedHash}.${this.format}?version=${this.version}`;
    },

    imgAlt() {
      if (this.alt) {
        return this.alt;
      }

      if (this.$config) {
        return this.$config.title;
      }

      return '';
    },
  },

  methods: {
    handleClick(event) {
      if (event.target.classList.contains('FileInput')) {
        return;
      }

      if (this.$refs.FileInput) {
        this.$refs.FileInput.click();
      }
    },
    handleError() {
      if (!this.isImagekitEnabled) {
        this.isEmpty = true;
      }

      this.isImagekitEnabled = false;
    },
    async FileInputChanged() {
      const file = this.$refs.FileInput.files[0];

      if (file) {
        if (file.size > 30 * 1024 * 1024) { // 30 Mb
          alert('Image is too large 30 Mb');

          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
          this.isLoading = true;

          const key = `${this.$store.state.liveEdit.assetsUrl.split('/').pop()}/${this.normalizedHash}.${this.format}`;

          const response = await this.$store.dispatch('liveEdit/uploadToS3', {
            contentType: file.type,
            key,
          });

          const body = new FormData();
          body.append('acl', response.acl);
          body.append('Content-Type', response['Content-Type']);

          for (const name in response.fields) {
            body.append(name, response.fields[name]);
          }
          body.append('file', file);

          await fetch(response.url, {
            method: 'POST',
            body,
          }).catch((error) => {
            console.error(error);
          });

          await this.$store.dispatch('liveEdit/updateOrCreate', { hash: 'live-image-version', content: String(Date.now()), lang: this.$liveEdit.getLocale(this.$i18n) });

          this.isEmpty = false;
        } catch (error) {
          console.error(error);
          alert('There was an error with connection');
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
