
export default {
  data() {
    return {
      isDarkModeReady: false,
      showMain: true,
    };
  },
  async fetch() {
    await this.$liveEdit.loadTexts();
  },

  head() {
    const title = this.$config.title;
    const description = this.$liveEdit.getText('home.hero.description');

    return {
      title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'description', name: 'description', content: description },
        { hid: 'og:description', property: 'og:description', content: description },
        { hid: 'twitter:description', property: 'twitter:description', content: description },
      ],
    };
  },
  computed: {
    isDarkMode() {
      return this.$store.state.ui.isDarkMode;
    },
  },
  watch: {
    isDarkMode: {
      immediate: true,
      handler(isDarkMode) {
        if (process.client) {
          if (this.isDarkModeReady) {
            const main = this.$refs.Main;
            if (main) {
              this.showMain = false;
              main.classList.remove('duration-400');
              main.classList.add('opacity-0');
              setTimeout(() => {
                main.classList.remove('duration-150');
                main.classList.add('duration-400');
                main.classList.remove('opacity-0');
                this.showMain = true;
              }, 450);
            }
          }

          document.documentElement.classList.toggle('dark', isDarkMode);
          document.body.classList.toggle('c_darkmode', isDarkMode); // cookieconsent
        }
      },
    },

    $route() {
      this.$store.commit('ui/setIsBurgerMenuOpen', false);
      setTimeout(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }, 200);
    },

    '$store.state.ui.isScrollFrozen': {
      handler(isScrollFrozen) {
        if (process.client) {
          document.documentElement.classList.toggle('overflow-hidden', isScrollFrozen);
        }
      },
    },
  },

  mounted() {
    this.darkModeChanged();
    this.watchSystemDarkMode();

    setTimeout(() => {
      this.isDarkModeReady = true;
    }, 800);
  },

  methods: {
    watchSystemDarkMode() {
      if (process.client) {
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
          this.darkModeChanged();
        });
      }
    },
    darkModeChanged() {
      const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      this.$store.commit('ui/setIsDarkMode', isDarkMode);
    },
  },
};
