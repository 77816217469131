
export default {
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    hash: {
      type: String,
      required: true,
    },
    richText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEditMode() {
      return this.$liveEdit.isEditMode();
    },
    text() {
      return this.$liveEdit.getText(this.hash);
    },
  },

  mounted() {
    if (this.text.includes('<p') && this.$el.closest('p')) {
      console.warn(`[LiveEdit] ${this.hash}: nested <p> tag`);
    }
  },
};
