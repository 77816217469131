import Vue from 'vue';

function directive(el, binding, vnode) {
  const MOBILE_OFF = (binding.arg === 'mobileoff');
  const WINDOW_WIDTH = window.innerWidth;

  // DISABLE ON MOBILE IF ARG `mobileoff` IS SET
  if (WINDOW_WIDTH < 768 && MOBILE_OFF) {
    // do something
    return false;
  } else {
    window.requestAnimationFrame(setScrollParallax);
  }

  function setScrollParallax() {
    // SOME SETTINGS HERE
    const fromCenter = binding.modifiers.center;
    // if center modifier is true, we want to start the magic from the middle of the screen
    const windowPosition = fromCenter ? (window.innerHeight / 2) : window.innerHeight;

    // document scrolled amount - parallax container offset from top + window height
    // this make sure to update --scroll-amount only when the elements are in the viewport
    let position = document.documentElement.scrollTop - el.offsetTop + windowPosition;
    const isBelow = document.documentElement.scrollTop > (el.offsetTop + el.offsetHeight);

    if (position < 0) { position = 0 } // Make sure we dont have negative values

    if (!isBelow) {
      el.style.setProperty('--css-parallax', position);
    }

    window.requestAnimationFrame(setScrollParallax);
  }
}

Vue.directive('css-parallax', { bind: directive });
