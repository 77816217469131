const isDarkModeDefault = process.client && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export const state = () => ({
  isDarkMode: isDarkModeDefault,
  isScrollFrozen: false,
  isBurgerMenuOpen: false,
  navItems: [
    {
      title: 'For Founders',
      link: '/founders',
      items: [
        {
          title: 'Overview',
          link: '/founders',
        },
        {
          title: 'Portfolio',
          link: '/investors/portfolio',
        },
      ],
    },
    {
      title: 'For Investors',
      link: '/investors/',
      items: [
        {
          title: 'Overview',
          link: '/investors/',
        },
        {
          title: 'Funds',
          link: '/investors/funds',
        },
        {
          title: 'Portfolio',
          link: '/investors/portfolio',
        },
      ],
    },
    {
      title: 'Infrastructure',
      link: '/infrastructure',
    },
    {
      title: 'Insights',
      link: '/insights',
    },
    {
      title: 'About us',
      link: '/about-us',
      items: [
        {
          title: 'About us',
          link: '/about-us',
        },
        {
          title: 'Events',
          link: '/events',
        },
        {
          title: 'Career',
          link: '/career',
        },
        {
          title: 'Contact us',
          link: '/contact',
        },
      ],
    },
  ],
});

export const mutations = {
  setIsDarkMode(state, payload) {
    state.isDarkMode = payload;
  },
  setIsBurgerMenuOpen(state, payload) {
    state.isBurgerMenuOpen = payload;
  },
  setIsScrollFrozen(state, payload) {
    state.isScrollFrozen = payload;
  },
};

export const getters = {

};

export const actions = {

};
