
// vue2-editor clone
const mergeDeep = (target, source) => {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
};

const defaultToolbar = [
  ['bold', 'italic', 'strike', 'link', { list: 'ordered' }, { list: 'bullet' }],
  ['clean', 'code-block'],
];

export default {
  props: {
    id: {
      type: String,
      default: 'quill-container',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    editorToolbar: {
      type: Array,
      default: () => [],
    },
    editorOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data: () => ({
    quill: null,
  }),
  watch: {
    value(value) {
      if (value !== this.quill.root.innerHTML && !this.quill.hasFocus()) {
        this.quill.root.innerHTML = value;
      }
    },
    disabled(status) {
      this.quill.enable(!status);
    },
  },
  mounted() {
    this.loadEditor();
  },
  beforeDestroy() {
    this.quill = null;
    delete this.quill;
  },
  methods: {
    loadEditor() {
      if (process.client) {
        if (window.Quill) {
          this.initializeEditor();
        } else {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = '//cdn.quilljs.com/1.3.6/quill.min.js';
          script.onload = () => window.Quill && this.initializeEditor();
          document.body.appendChild(script);

          const style = document.createElement('link');
          style.href = '//cdn.quilljs.com/1.3.6/quill.snow.css';
          style.type = 'text/css';
          style.rel = 'stylesheet';
          document.body.appendChild(style);
        }
      }
    },
    initializeEditor() {
      this.registerPrototypes();
      this.registerModules();
      this.setupQuillEditor();
      this.handleInitialContent();
      this.registerEditorEventListeners();
      this.$emit('ready', this.quill);
    },
    setupQuillEditor() {
      const editorConfig = {
        debug: false,
        modules: this.setModules(),
        theme: 'snow',
        placeholder: this.placeholder ? this.placeholder : '',
        readOnly: this.disabled ? this.disabled : false,
      };
      this.prepareEditorConfig(editorConfig);
      this.quill = new Quill(this.$refs.quillContainer, editorConfig);
    },
    setModules() {
      const modules = {
        toolbar: this.editorToolbar.length ? this.editorToolbar : defaultToolbar,
      };
      return modules;
    },
    prepareEditorConfig(editorConfig) {
      if (
        Object.keys(this.editorOptions).length > 0 &&
        this.editorOptions.constructor === Object
      ) {
        if (
          this.editorOptions.modules &&
          typeof this.editorOptions.modules.toolbar !== 'undefined'
        ) {
          // We don't want to merge default toolbar with provided toolbar.
          delete editorConfig.modules.toolbar;
        }
        mergeDeep(editorConfig, this.editorOptions);
      }
    },
    registerPrototypes() {
      Quill.prototype.getHTML = function() {
        return this.container.querySelector('.ql-editor').innerHTML;
      };
      Quill.prototype.getWordCount = function() {
        return this.container.querySelector('.ql-editor').innerText.length;
      };
    },
    registerEditorEventListeners() {
      this.quill.on('text-change', this.handleTextChange);
      this.quill.on('selection-change', this.handleSelectionChange);
      this.listenForEditorEvent('text-change');
      this.listenForEditorEvent('selection-change');
      this.listenForEditorEvent('editor-change');
    },
    listenForEditorEvent(type) {
      this.quill.on(type, (...args) => {
        this.$emit(type, ...args);
      });
    },
    handleInitialContent() {
      if (this.value) { this.quill.root.innerHTML = this.value } // Set initial editor content
    },
    handleSelectionChange(range, oldRange) {
      if (!range && oldRange) { this.$emit('blur', this.quill) } else if (range && !oldRange) { this.$emit('focus', this.quill) }
    },
    handleTextChange() {
      const editorContent = this.quill.getHTML() === '<p><br></p>' ? '' : this.quill.getHTML();
      this.$emit('input', editorContent);
    },
    registerModules() {
      const Clipboard = Quill.import('modules/clipboard');
      const Delta = Quill.import('delta');

      class ClearPaste extends Clipboard {
        onPaste(event) {
          event.preventDefault();
          const range = this.quill.getSelection();
          const text = event.clipboardData.getData('text/plain');
          const delta = new Delta().retain(range.index).delete(range.length).insert(text);
          const index = text.length + range.index;
          const length = 0;

          this.quill.updateContents(delta, 'user');
          this.quill.setSelection(index, length, 'user');
          this.quill.scrollIntoView();
        }
      }

      Quill.register('modules/clipboard', ClearPaste, true);
    },
  },
};
