
export default {
  props: {
    loop: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: String,
      default: '3s',
    },
    delay: {
      type: String,
      default: '0ms',
    },
    length: {
      type: String,
      default: '15%',
    },
  },

  computed: {
    cssVariables() {
      return {
        '--comet-length': this.length,
        '--animation-duration': this.duration,
        '--animation-delay': this.delay,
      };
    },
  },
};
