import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c9a8876a = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _61abdb24 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _9776b6f4 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _381a336e = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _9d690658 = () => interopDefault(import('../pages/founders/index.vue' /* webpackChunkName: "pages/founders/index" */))
const _7b2b4153 = () => interopDefault(import('../pages/infrastructure/index.vue' /* webpackChunkName: "pages/infrastructure/index" */))
const _ab1f9d6a = () => interopDefault(import('../pages/insights/index.vue' /* webpackChunkName: "pages/insights/index" */))
const _6821fabe = () => interopDefault(import('../pages/investors.vue' /* webpackChunkName: "pages/investors" */))
const _37eafac1 = () => interopDefault(import('../pages/investors/index.vue' /* webpackChunkName: "pages/investors/index" */))
const _6c1a0f00 = () => interopDefault(import('../pages/investors/funds/index.vue' /* webpackChunkName: "pages/investors/funds/index" */))
const _4c1e89fa = () => interopDefault(import('../pages/investors/portfolio/index.vue' /* webpackChunkName: "pages/investors/portfolio/index" */))
const _6a636db8 = () => interopDefault(import('../pages/investors/funds/_slug.vue' /* webpackChunkName: "pages/investors/funds/_slug" */))
const _47f05582 = () => interopDefault(import('../pages/media-mentions.vue' /* webpackChunkName: "pages/media-mentions" */))
const _5a0faca2 = () => interopDefault(import('../pages/press-kit.vue' /* webpackChunkName: "pages/press-kit" */))
const _57b4e14a = () => interopDefault(import('../pages/ui-kit.vue' /* webpackChunkName: "pages/ui-kit" */))
const _04d657f4 = () => interopDefault(import('../pages/contact/pitch.vue' /* webpackChunkName: "pages/contact/pitch" */))
const _65191db4 = () => interopDefault(import('../pages/career/_slug.vue' /* webpackChunkName: "pages/career/_slug" */))
const _3b8775fe = () => interopDefault(import('../pages/events/_slug.vue' /* webpackChunkName: "pages/events/_slug" */))
const _7974a00b = () => interopDefault(import('../pages/infrastructure/_slug.vue' /* webpackChunkName: "pages/infrastructure/_slug" */))
const _5da49b64 = () => interopDefault(import('../pages/infrastructure/_slug/index.vue' /* webpackChunkName: "pages/infrastructure/_slug/index" */))
const _ae8cdffa = () => interopDefault(import('../pages/insights/_slug.vue' /* webpackChunkName: "pages/insights/_slug" */))
const _d6eb8792 = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _84f04296 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _c9a8876a,
    name: "about-us"
  }, {
    path: "/career",
    component: _61abdb24,
    name: "career"
  }, {
    path: "/contact",
    component: _9776b6f4,
    name: "contact"
  }, {
    path: "/events",
    component: _381a336e,
    name: "events"
  }, {
    path: "/founders",
    component: _9d690658,
    name: "founders"
  }, {
    path: "/infrastructure",
    component: _7b2b4153,
    name: "infrastructure"
  }, {
    path: "/insights",
    component: _ab1f9d6a,
    name: "insights"
  }, {
    path: "/investors",
    component: _6821fabe,
    children: [{
      path: "",
      component: _37eafac1,
      name: "investors"
    }, {
      path: "funds",
      component: _6c1a0f00,
      name: "investors-funds"
    }, {
      path: "portfolio",
      component: _4c1e89fa,
      name: "investors-portfolio"
    }, {
      path: "funds/:slug",
      component: _6a636db8,
      name: "investors-funds-slug"
    }]
  }, {
    path: "/media-mentions",
    component: _47f05582,
    name: "media-mentions"
  }, {
    path: "/press-kit",
    component: _5a0faca2,
    name: "press-kit"
  }, {
    path: "/ui-kit",
    component: _57b4e14a,
    name: "ui-kit"
  }, {
    path: "/contact/pitch",
    component: _04d657f4,
    name: "contact-pitch"
  }, {
    path: "/career/:slug",
    component: _65191db4,
    name: "career-slug"
  }, {
    path: "/events/:slug",
    component: _3b8775fe,
    name: "events-slug"
  }, {
    path: "/infrastructure/:slug",
    component: _7974a00b,
    children: [{
      path: "",
      component: _5da49b64,
      name: "infrastructure-slug"
    }]
  }, {
    path: "/insights/:slug",
    component: _ae8cdffa,
    name: "insights-slug"
  }, {
    path: "/page/:slug?",
    component: _d6eb8792,
    name: "page-slug"
  }, {
    path: "/",
    component: _84f04296,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
