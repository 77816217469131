
import LiveTextDialog from './LiveTextDialog';

export default {
  components: {
    LiveTextDialog,
  },

  computed: {
    token() {
      return this.$store.state.liveEdit.token;
    },
  },

  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      if (this.$route.query.isLiveEdit) { // iframe usage
        const token = this.$route.query.accessToken || window.localStorage.getItem('auth._token.local');
        this.$store.commit('liveEdit/setIsIframe', true);
        this.$store.commit('liveEdit/setIsEditModeEnabled', true);
        this.$store.commit('liveEdit/setToken', token);
      } else {
        const token = window.localStorage.getItem('live-edit.auth-token');
        this.$store.commit('liveEdit/setToken', token);
      }
    }
  },
};
