
export default {
  data() {
    return {
      expandedItems: [],
    };
  },
  methods: {
    toggleItem(item) {
      if (item.items && item.items.length) {
        const index = this.expandedItems.indexOf(item.title);

        if (index !== -1) {
          this.expandedItems.splice(index, 1);
        } else {
          this.expandedItems.push(item.title);
        }
      }
    },
  },
};
