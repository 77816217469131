
export default {
  data() {
    return {
      showCookiesBar: false,
      cookieconsent: null,
    };
  },
  mounted() {
    if (window.cookieconsent) {
      return;
    }
    // eslint-disable-next-line no-undef
    window.cookieconsent = initCookieConsent();

    this.showCookiesBar = true;

    window.cookieconsent.run({
      current_lang: 'en',
      page_scripts: true,
      autorun: false,
      auto_language: 'document',
      cookie_name: 'cc_cookie',
      cookie_expiration: 182, // 182 (days)

      gui_options: {
        consent_modal: {},
        settings_modal: {
          layout: 'box',
        },
      },

      onAccept: (cookie) => {
        this.showCookiesBar = false;
        // this.handleGoogleTaskManager(cookie);
      },

      languages: {
        en: {
          consent_modal: {
            title: '',
            description: '',
          },
          settings_modal: {
            title: 'Cookie settings',
            save_settings_btn: 'Save',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',
            blocks: [
              {
                title: '',
                description: 'Our website uses cookies to help us improve it. In order for us to use cookies, you must allow us to do so. By clicking on the "Accept all" button you give this consent.',
              },
              {
                title: 'Essential',
                description: 'These are technical files that are necessary for the proper functioning of our website and all of its features. Your consent is not required for these cookies and it is not possible to remove them.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
              },
              {
                title: 'Analytics',
                description: 'These cookies allow us to measure the performance of our website and our online campaigns. We use them to determine the number of visits, the source of visits and other parameters. We collect data in an aggregated form that does not allow us to trace the data to a specific user. If you disable these cookies, we will not be able to analyze the performance of our website and optimize it for the easiest possible use.',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
      },
    });

    // open settings from external html content, usage: <a href="javascript:openCookiesSettings()"></a>
    window.openCookiesSettings = () => {
      if (window.cookieconsent) {
        window.cookieconsent.showSettings();
      }
    };
  },

  methods: {
    accept() {
      try {
        window.cookieconsent.accept('all');
      } catch (error) {
        console.log(error);
        this.showCookiesBar = false;
      }
    },
  },
};
