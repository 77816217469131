import Vue from 'vue';

export default function() {
  Vue.prototype.$getNext = (list, index) => {
    const isLast = index === list.length - 1;
    return list[isLast ? 0 : index + 1];
  };

  Vue.prototype.$getPrev = (list, index) => {
    return list[index === 0 ? list.length - 1 : index - 1];
  };

  Vue.prototype.$sleep = (ms = 0) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
};
