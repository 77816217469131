import Vue from 'vue';
import animateScrollTo from 'animated-scroll-to';

Vue.directive('animate-scroll-to', {
  bind: (el, { value }) => {
    el.addEventListener('click', (event) => {
      if (typeof value === 'string') {
        value = document.querySelector(value);
      }

      const headerHeight = document.querySelector('header').offsetHeight;

      animateScrollTo(value, {
        verticalOffset: -headerHeight,
        horizontalOffset: 0,
      });

      event.preventDefault();
    });
  },
});

Vue.prototype.$animateScrollTo = animateScrollTo;
