
export default {
  methods: {
    toggleEditMode() {
      this.$store.commit('liveEdit/setIsEditModeEnabled', !this.$store.state.liveEdit.isEditModeEnabled);
    },
    logout() {
      this.$store.commit('liveEdit/setToken', null);
    },
  },
};
