import Axios from 'axios';

const initAxios = () => {
  const axios = Axios.create({});

  // Request helpers ($get, $post, ...)
  for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    axios['$' + method] = function() { return this[method].apply(this, arguments).then((res) => res && res.data) };
  }

  return axios;
};

const axios = initAxios();

export default {
  namespaced: true,
  state: {
    isEditModeEnabled: false,
    isIframe: false,
    baseUrl: null,
    assetsUrl: null,
    imagekitUrl: null,
    token: null,
    texts: [],

    dialog: {
      isOpen: false,
      hash: null,
      text: null,
      lang: null,
      richText: false,
    },
  },
  mutations: {
    set(state, payload) {
      state.texts = payload;
    },
    add(state, payload) {
      state.texts.push(payload);
    },
    update(state, { hash, lang, payload }) {
      state.texts = state.texts.map((item) => {
        if (item.hash === hash && item.lang === lang) {
          item = { ...item, ...payload };
        }

        return item;
      });
    },
    remove(state, { hash, lang }) {
      state.texts = state.texts.filter((item) => !(item.hash === hash && item.lang === lang));
    },

    updateDialog(state, payload) {
      state.dialog = { ...state.dialog, ...payload };
    },

    setToken(state, payload) {
      axios.defaults.headers.Authorization = payload;
      state.token = payload;

      if (payload) {
        window.localStorage.setItem('live-edit.auth-token', payload);
      } else {
        window.localStorage.removeItem('live-edit.auth-token');
      }
    },
    setBaseUrl(state, payload) {
      axios.defaults.baseURL = payload;
      state.baseUrl = payload;
    },
    setAssetsUrl(state, payload) {
      state.assetsUrl = payload;
    },
    setImagekitUrl(state, payload) {
      state.imagekitUrl = payload;
    },
    setIsEditModeEnabled(state, payload) {
      state.isEditModeEnabled = payload;
    },
    setIsIframe(state, payload) {
      state.isIframe = payload;
    },
  },

  actions: {
    async loadTexts({ commit }, { lang }) {
      try {
        const items = await axios.$get('/public/live-texts', {
          params: {
            lang,
          },
        });

        if (items && Array.isArray(items)) {
          commit('set', items);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async updateOrCreate({ commit, state }, payload) {
      const item = state.texts.find((text) => text.hash === payload.hash);

      if (item) {
        if (!payload.content.trim().length) { // remove
          await axios.$delete('/live-texts', {
            params: {
              lang: payload.lang,
              hash: item.hash,
            },
          });

          commit('remove', {
            hash: item.hash,
            lang: item.lang,
          });
        } else if (item.content !== payload.content) { // update
          const data = await axios.$put(`/live-texts/${item.hash}`, payload, {
            params: {
              lang: payload.lang,
            },
          });

          commit('update', {
            hash: item.hash,
            lang: item.lang,
            payload: data,
          });
        }
      } else { // add
        const item = await axios.$post('/live-texts', payload);

        commit('add', item);
      }
    },

    async addToList({ getters, dispatch }, { hash, lang, index }) {
      const list = getters.getList(hash);
      list.push(index);

      await dispatch('updateOrCreate', {
        hash,
        lang,
        content: list.join(','),
      });
    },

    async removeFromList({ getters, dispatch }, { hash, lang, index }) {
      const list = getters.getList(hash).filter((item) => item !== index);

      await axios.$delete('/live-texts', {
        params: {
          lang,
          hash: `${hash}.${index}`,
          regex: true,
        },
      });

      await dispatch('updateOrCreate', {
        hash,
        lang,
        content: list.join(','),
      });
    },

    async getHistory(_, { hash, lang }) {
      try {
        const items = await axios.$get(`/live-texts/${hash}/history`, {
          params: {
            lang,
          },
        });

        return items;
      } catch (error) {
        console.error(error);
      }
    },

    async login({ commit }, payload) {
      try {
        const response = await axios.$post('/public/users/login', payload);

        commit('setToken', `Bearer ${response.token}`);
        commit('setIsEditModeEnabled', true);
      } catch (error) {
        console.error(error);
      }
    },

    async uploadToS3(_, data) {
      const response = await axios.$post('/s3/upload', data);

      return response;
    },
  },

  getters: {
    getText: (state) => (hash) => {
      const text = state.texts.find((text) => text.hash === hash);

      if (state.token && state.isEditModeEnabled && !(text && text.content)) {
        console.warn(`[LiveEdit] ${hash} not found`);

        return 'insert text';
      }

      return text ? text.content : '';
    },

    getList: (state) => (hash) => {
      const text = state.texts.find((text) => text.hash === hash);

      if (state.token && !(text && text.content)) {
        console.warn(`[LiveEdit] ${hash} not found`);
      }

      if (text && text.content) {
        return text.content.split(',');
      }

      return [];
    },

    isEditMode: (state) => {
      return state.token && state.isEditModeEnabled;
    },
  },
};
