
import LiveRichEditor from './LiveRichEditor';

export default {
  components: {
    LiveRichEditor,
  },
  data() {
    return {
      isSubmitting: false,
      isHistoryOpen: false,
      historyItems: [],
      value: '',
    };
  },

  computed: {
    isOpen: {
      get() {
        return this.$store.state.liveEdit.dialog.isOpen;
      },
      set(value) {
        this.$store.commit('liveEdit/updateDialog', {
          isOpen: value,
        });
      },
    },
    text() {
      return this.$store.state.liveEdit.dialog.text;
    },
    hash() {
      return this.$store.state.liveEdit.dialog.hash;
    },
    richText() {
      return this.$store.state.liveEdit.dialog.richText;
    },
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.value = this.text !== 'insert text' ? this.text : '';
        this.isHistoryOpen = false;

        this.$nextTick(() => {
          if (this.$refs.Textarea) {
            this.$refs.Textarea.focus();
          }
        });
      }
    },
  },

  methods: {
    richEditorReady() {
      if (this.$refs.Editor) {
        this.$refs.Editor.quill.focus();

        const toolbar = this.$refs.Editor.quill.getModule('toolbar');
        toolbar.addHandler('code-block', () => {
          this.$store.commit('liveEdit/updateDialog', {
            richText: false,
          });
        });
      }
    },
    async handleSaveText() {
      this.isSubmitting = true;
      try {
        await this.$store.dispatch('liveEdit/updateOrCreate', { hash: this.hash, content: this.value, lang: this.$liveEdit.getLocale(this.$i18n) });
      } catch (error) {
        console.error(error);
        alert('There was an error with connection');
      }
      this.isSubmitting = false;
      this.isOpen = false;
    },

    handleBackdropClicked(event) {
      if ((event.target || event.srcElement).classList.contains('DialogBackdrop')) {
        this.isOpen = false;
      }
    },

    async toggleHistory() {
      if (this.isHistoryOpen) {
        this.isHistoryOpen = false;
      } else {
        this.isHistoryOpen = true;

        try {
          this.historyItems = await this.$store.dispatch('liveEdit/getHistory', { hash: this.hash, lang: this.$liveEdit.getLocale(this.$i18n) });
        } catch (error) {
          console.error(error);
          alert('There was an error with connection');
        }

        await this.$nextTick();
        this.$refs.History.scrollTop = this.$refs.History.scrollHeight;
      }
    },
  },
};
