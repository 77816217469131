
export default {
  computed: {
    itemsPrimary() {
      return this.$store.state.ui.navItems;
    },
    itemsSecondary() {
      return this.$store.state.ui.navItems.find(({ link }) => link === '/about-us').items.filter(({ link }) => link !== '/about-us');
    },
  },
};
