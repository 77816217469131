
import { nanoid } from 'nanoid';

export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    hash: {
      type: String,
      required: true,
    },
  },
  computed: {
    isEditMode() {
      return this.$liveEdit.isEditMode();
    },
    list() {
      return this.$liveEdit.getList(this.hash);
    },
  },

  watch: {
    isEditMode: {
      immediate: true,
      handler(isEditMode) {
        if (isEditMode) {
          this.loadSortable();
        }
      },
    },
  },

  methods: {
    add() {
      this.$liveEdit.addToList(this.hash, nanoid(6));
    },

    loadSortable() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//cdn.jsdelivr.net/npm/sortablejs@1.15.0/Sortable.min.js';
      script.onload = () => window.Sortable && this.initSortable();
      document.body.appendChild(script);
    },

    async initSortable() {
      await this.$nextTick();
      window.Sortable.create(this.$el, {
        handle: '.LiveListItemHandle',
        animation: 100,
        onEnd: ({ newIndex, oldIndex }) => {
          const list = [...this.list];
          const target = list.splice(oldIndex, 1)[0];
          list.splice(newIndex, 0, target);

          this.$liveEdit.updateList(this.hash, list.join(','));
        },
      });
    },
  },
};
